const GuaranteeFooter = ({ ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_3454_3396)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.58301 10.0002C1.58301 5.35177 5.35128 1.5835 9.99967 1.5835C14.2186 1.5835 17.7125 4.68758 18.3221 8.73648L17.1471 7.75733C16.8289 7.49216 16.356 7.53515 16.0908 7.85336C15.8257 8.17157 15.8686 8.64449 16.1869 8.90966L18.6869 10.993C19.0051 11.2582 19.478 11.2152 19.7432 10.897C19.9089 10.6981 19.9542 10.4388 19.8873 10.2076C19.9062 10.1417 19.9163 10.0721 19.9163 10.0002C19.9163 4.52334 15.4765 0.0834961 9.99967 0.0834961C4.52285 0.0834961 0.0830078 4.52334 0.0830078 10.0002C0.0830078 15.477 4.52285 19.9168 9.99967 19.9168C14.113 19.9168 17.6397 17.4127 19.1417 13.8486C19.3026 13.4669 19.1235 13.0271 18.7418 12.8662C18.3601 12.7054 17.9203 12.8844 17.7594 13.2661C16.4835 16.2938 13.4886 18.4168 9.99967 18.4168C5.35128 18.4168 1.58301 14.6486 1.58301 10.0002Z"
          fill="#999999"
        />
        <path
          d="M10.07 6.06365C9.49004 6.10365 9.02004 6.25365 8.64004 6.52365C8.13004 6.87365 7.88004 7.37365 7.88004 8.03365C7.88004 8.77365 8.22004 9.33365 8.92004 9.71365L10.07 10.1337V12.4737C9.76004 12.4337 9.52004 12.3337 9.35004 12.1737C9.12004 11.9537 8.99004 11.5737 8.95004 11.0537H7.79004C7.85004 11.8737 8.09004 12.4837 8.51004 12.8837C8.87004 13.2237 9.39004 13.4137 10.07 13.4637V14.3437H10.73V13.4637C11.34 13.4237 11.84 13.2737 12.24 13.0137C12.78 12.6537 13.05 12.1237 13.05 11.4137C13.05 10.6837 12.7 10.1237 12.01 9.73365C11.95 9.69365 11.52 9.53365 10.73 9.24365V7.04365C10.98 7.07365 11.18 7.14365 11.33 7.25365C11.57 7.42365 11.72 7.71365 11.78 8.13365H12.92C12.82 7.40365 12.57 6.87365 12.16 6.55365C11.81 6.27365 11.33 6.10365 10.73 6.06365V5.24365H10.07V6.06365ZM10.73 10.3737C10.77 10.3837 10.8 10.3937 10.84 10.4137C11.53 10.6537 11.88 11.0037 11.88 11.4537C11.88 11.7837 11.74 12.0437 11.46 12.2237C11.26 12.3537 11.01 12.4337 10.73 12.4737V10.3737ZM10.07 8.99365C9.39004 8.73365 9.05004 8.41365 9.05004 8.03365C9.05004 7.66365 9.18004 7.39365 9.45004 7.23365C9.60004 7.13365 9.81004 7.06365 10.07 7.03365V8.99365Z"
          fill="#999999"
        />
      </g>
      <defs>
        <clipPath id="clip0_3454_3396">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default GuaranteeFooter
